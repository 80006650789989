//LIBS
import GooglePlaces, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useEffect } from 'react';

function FieldPlace(props) {
    const defaultCities = [
        { label: 'Paris, France', value: '48.856614,2.3522219' },
        { label: 'Marseille, France', value: '43.296482,5.36978' },
        { label: 'Lyon, France', value: '45.764043,4.835659' },
        { label: 'Toulouse, France', value: '43.604652,1.444209' },
        { label: 'Nice, France', value: '43.710173,7.261953' },
        { label: 'Nantes, France', value: '47.218371,-1.553621' },
        { label: 'Strasbourg, France', value: '48.573405,7.752111' },
        { label: 'Montpellier, France', value: '43.610769,3.876716' },
        { label: 'Bordeaux, France', value: '44.837789,-0.57918' },
        { label: 'Lille, France', value: '50.62925,3.057256' },
        { label: 'Rennes, France', value: '48.117266,-1.6777926' },
        { label: 'Reims, France', value: '49.258329,4.031696' },
        { label: 'Saint-Étienne, France', value: '45.439695,4.387178' },
        { label: 'Toulon, France', value: '43.124228,5.928' },
        { label: 'Le Havre, France', value: '49.493897,0.107973' },
        { label: 'Grenoble, France', value: '45.188529,5.724524' },
        { label: 'Dijon, France', value: '47.322047,5.04148' },
        { label: 'Angers, France', value: '47.478419,-0.563166' },
        { label: 'Nîmes, France', value: '43.837425,4.360068' },
        { label: 'Villeurbanne, France', value: '45.766902,4.89096' },
        { label: 'Le Mans, France', value: '48.00611,0.199556' },
        { label: 'Aix-en-Provence, France', value: '43.529742,5.447427' },
        { label: 'Clermont-Ferrand, France', value: '45.777222,3.087025' },
        { label: 'Brest, France', value: '48.390394,-4.486076' },
        { label: 'Limoges, France', value: '45.833619,1.261105' },
        { label: 'Tours, France', value: '47.394144,0.68484' },
        { label: 'Amiens, France', value: '49.894067,2.295753' },
        { label: 'Perpignan, France', value: '42.688659,2.894833' },
        { label: 'Metz, France', value: '49.119309,6.175715' },
        { label: 'Besançon, France', value: '47.237829,6.024054' },
        { label: 'Boulogne-Billancourt, France', value: '48.833832,2.247356' },
        { label: 'Orléans, France', value: '47.902964,1.909251' },
    ];

    function onChangeToProps(value) {
        if (value?.label) {
            geocodeByAddress(value.label)
                .then((results) => getLatLng(results[0]))
                .then(({ lat, lng }) => props.onChange(`${lat},${lng}`, value?.label));
        } else props.onChange('');
    }

    useEffect(() => {
        if (props?.isJobsPage === true) {
            require('./place.css');
        }
        //eslint-disable-next-line
    }, []);

    let selectProps = {
        id: props.id ?? 'city',
        name: props.id ?? 'city',
        isClearable: props.isClearable ?? true,
        placeholder: props.placeholder,
        onChange: onChangeToProps,
        cacheOptions: true,
        //defaultOptions: true,
        noOptionsMessage: () => 'Aucun résultat',
        //select 10 random cities from defaultCities
        defaultOptions: defaultCities.sort(() => Math.random() - Math.random()).slice(0, 8),
        styles: {
            control: (provided) => ({
                ...provided,
                //text size
                fontSize: '0.875rem',
                height: props?.isJobsPage ? '2.25rem' : '2.5rem',
                minHeight: props?.isJobsPage ? '2.25rem' : '2.5rem',
                borderRadius: props?.isJobsPage ? '0 9999px 9999px 0' : '1rem',
                // borderColor: props?.isJobsPage ? 'rgb(209 213 219)' : '#e2e8f0',
                border: 'none',
                boxShadow: 'none',
                cursor: 'text',
                backgroundColor: '#F7F9FF',
                borderLeft: props?.isJobsPage ? '1px solid #cccccc' : '',
                /* borderLeft: '1px solid #cccccc', */
            }),
            menu: (provided) => ({
                ...provided,
                boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#f7fafc' : 'white',
                color: state.isSelected ? '#1a202c' : '#718096',
                fontSize: '0.875rem',
                '&:hover': {
                    backgroundColor: '#f7fafc',
                    color: '#1a202c',
                },
                cursor: 'pointer',
            }),
        },
    };

    if (props.defaultValue && props.defaultValue.label !== '') {
        selectProps = {
            ...selectProps,
            defaultValue: props.defaultValue,
        };
    }

    return (
        <div className="z-50">
            <GooglePlaces
                // apiKey={'AIzaSyDKJXi6lZ55TuzL75f8Y5ymlX8wA9zjejc'}
                apiKey={'AIzaSyAlzFiaR8HippI1vim_LlFUcVNf6kWjYGw'}
                selectProps={selectProps}
            />
        </div>
    );
}

export default FieldPlace;
